<template>
  <div :class="!topBarShow ? 'blindBox-container active' : 'blindBox-container'"
    :style="{ backgroundImage: 'url(' + activityData.thumb + ')' }">
    <!-- <div class="header" v-if="topBarShow">
      <van-nav-bar @click-left="goBack" @click-right="share" :title="activityData.title" left-arrow
        :safe-area-inset-top="true" :border="false">
        <template #right>
          <img class="share-icon" src="../../assets/share.png" alt="" />
        </template>
      </van-nav-bar>
    </div> -->
    <div class="section">
      <!-- <img class="section-title" src="../../assets/blind-box-title.png" /> -->
      <div class="subtitle">
        <span>惊喜奖品等你领取</span>
      </div>
    </div>
    <div class="article">
      <div class="article-title">
        <img class="icon" src="../../assets/star.png" alt="" />
        <div class="text">
          {{ activityData.long_title }}
        </div>
      </div>
      <div class="label">
        <div class="label-item" v-for="(item, index) in activityData.goods" :key="item">
          <span class="label-item-content" v-if="index < 5">
            {{ item.title }}{{ item.goods_title }}
          </span>
        </div>
        <div class="label-item">
          <span class="label-item-content" @click="allGoods">
            全部奖品>>
          </span>
        </div>
      </div>
      <div class="blind-box-list">
        <div class="blind-box" v-for="(item, index) in blindBoxs" :key="item">
          <div class="box close-box" @click="openBlindBox(index)" v-if="!item.open">
            <div class="img">
              <img src="../../assets/blind-box.png" />
            </div>
            <div class="btn">拆盒</div>
          </div>
          <div class="box open-box" v-else>
            <div class="img">
              <img :src="item.thumb" />
            </div>
            <div class="btn">{{ item.prize_title }}</div>
          </div>
        </div>
      </div>
      <div :class="isOpen ? 'operation' : 'operation no-click'">
        <div class="change" @click="changeBox">
          <div class="btn-text">
            <div class="text">换一批</div>
          </div>
          <img class="base" src="../../assets/change-btn-bg.png" />
        </div>
        <div class="random">
          <div class="btn" @click="random">
            <div class="btn-text">
              <div class="text">随机拆</div>
              <div class="game-num">(剩余{{ activityData.number }}次)</div>
            </div>
            <img class="base" src="../../assets/random-btn-bg.png" />
          </div>
          <div class="tips" v-if="activityData.other > 0">
            分享好友机会剩余{{ activityData.other }}次
          </div>
          <div class="choujiang" @click="DrawRecord">抽奖记录>></div>
        </div>
      </div>
      <div class="recordList">
        <div v-if="activityData.record_log" :class="'record-box ' + recordAnimationName"
          :style="'--time:' + activityData.fdata.length + 's'">
          <div class="record-item" v-for="(item, index) in activityData.record_log" :key="index">
            <span>{{ item.created_at }}</span>
            <span>{{ item.content }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="rule" @click="jumpRule">玩法规则</div>
    <div class="winning-record" @click="jumpWinning">中奖记录</div>
    <div class="dialog-box" v-if="dialog">
      <div class="dialog">
        <div class="dialog-content">{{ winningInformation.title }}</div>
        <div class="goods">
          <img :src="winningInformation.thumb" />
        </div>
        <div class="button" @click="hiddenDialog"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import Api from "@/API";
import { Toast, NavBar } from "vant";
import { defineComponent } from "vue";
import { getPhoneSystem, isWeiChat, getUrlParam } from "@/utils/environment";
import { useStore } from "vuex";
export default defineComponent({
  name: "blindBox",
  components: {
    [NavBar.name]: NavBar,
  },
  setup(props) {
    //使用vuex
    const store = useStore();
    //使用路由
    const router = useRouter();
    //创建API对象
    const API = new Api();
    // 顶部栏显示隐藏
    const topBarShow = !isWeiChat();
    // 活动是否开启
    let isOpen = ref(false);
    // 活动数据
    let activityData = ref({});
    // 盲盒列表
    let blindBoxs = ref([
      { title: "", thumb: "", open: false },
      { title: "", thumb: "", open: false },
      { title: "", thumb: "", open: false },
      { title: "", thumb: "", open: false },
      { title: "", thumb: "", open: false },
      { title: "", thumb: "", open: false },
      { title: "", thumb: "", open: false },
      { title: "", thumb: "", open: false },
      { title: "", thumb: "", open: false },
    ]);
    // 中奖列表轮播动画class
    const recordAnimationName = ref("");
    // 抽奖次数
    let gameNum = ref(0);
    // 分享得次数
    let shareGetNum = ref(0);
    // 中奖对话框显示隐藏
    let dialog = ref(false);
    // 中奖信息
    let winningInformation = ref({});
    // 弹出框
    let show = ref(false)
    // 获取活动数据
    const getRecord = async () => {
      let res = await API.getBlindBoxData({
        token: store.state.Token,
        luck_id: store.state.Luckid
      });
      if (res) {
        isOpen.value = true;
        activityData.value = res.data;
        if (res.data.record_log.length > 5) {
          recordAnimationName.value = "recordRotation";
        }
        if (res.data.goods.length > 5) {
          res.data.goods = res.data.goods.slice(0, 5)
        }
      }
    };
    // 开盲盒
    const openBlindBox = async (data) => {
      if (!isOpen.value) {
        return Toast.fail("活动未开启或已结束");
      }
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      getRandomBox(data);
    };
    // 换一批
    const changeBox = () => {
      if (!isOpen.value) {
        return Toast.fail("活动未开启或已结束");
      }
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      setTimeout(() => {
        Toast.clear();
        blindBoxs.value = [
          { title: "", thumb: "", open: false },
          { title: "", thumb: "", open: false },
          { title: "", thumb: "", open: false },
          { title: "", thumb: "", open: false },
          { title: "", thumb: "", open: false },
          { title: "", thumb: "", open: false },
          { title: "", thumb: "", open: false },
          { title: "", thumb: "", open: false },
          { title: "", thumb: "", open: false },
        ];
      }, 1000);
    };
    // 随机抽
    const random = () => {
      if (!isOpen.value) {
        return Toast.fail("活动未开启或已结束");
      }
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      // 获取未开盲盒
      let prize = [];
      blindBoxs.value.forEach((item, index) => {
        if (!item.open) {
          prize.push(index);
        }
      });
      // 在未开盲盒中随机抽取
      if (prize.length > 0) {
        let random = Math.ceil(Math.random() * prize.length);
        getRandomBox(prize[random - 1]);
      } else {
        Toast("无盲盒可拆！");
      }
    };
    // 获取中奖盲盒
    const getRandomBox = async (data) => {
      let res = await API.openBox({
        token: store.state.Token,
        luck_id: store.state.Luckid
      });
      if (res.status != 'error') {
        Toast.clear();
        blindBoxs.value[data].thumb = res.data.thumb;
        blindBoxs.value[data].title = res.data.title;
        blindBoxs.value[data].prize_title = res.data.prize_title;
        blindBoxs.value[data].open = true;
        winningInformation.value = res.data;
        dialog.value = true;
        getRecord();
      } else {
        Toast(res.msg);
      }
    };
    // 中奖对话框隐藏
    const hiddenDialog = () => {
      dialog.value = false;
      if (winningInformation.value.type == 3) {
        jumpShop();
      }
    };
    // 跳转好店商品列表
    const jumpShop = () => {
      const goods = JSON.stringify(winningInformation.value.sku)
      const record_id = winningInformation.value.record_id
      try {
        if (isWeiChat()) {
          wx.miniProgram.navigateTo({
            url: `/pages/receivePrizes/receivePrizes?winningInformation=${JSON.stringify(winningInformation.value.sku)}&blind_id=${winningInformation.value.record_id}`,
          });
          return;
        }
        if (getPhoneSystem() === "Android") {
          // 跳转到Android另外页面
          window.android.turnToShop(goods, record_id);
          return;
        }
        if (getPhoneSystem() === "IOS") {
          // 跳转到ios另外页面
          window.webkit.messageHandlers.shareToWX.postMessage("goods=" + JSON.stringify(winningInformation.value.sku) + "&record_id=" + winningInformation.value.record_id);
          return;
        }
        // if (isWeiChat()) {
        //   wx.miniProgram.navigateTo({
        //     url: `/pages/receivePrizes/receivePrizes?winningInformation=${JSON.stringify(winningInformation.value.sku)}&blind_id=${winningInformation.value.record_id}`,
        //   });
        // } else {
        //   if (getPhoneSystem() === "Android") {
        //     // 跳转到Android另外页面
        //     window.android.turnToShop("goods=" + JSON.stringify(winningInformation.value.sku) + "&record_id=" + winningInformation.value.record_id);
        //   } else if (getPhoneSystem() === "IOS") {
        //     // 跳转到ios另外页面
        //     window.webkit.messageHandlers.shareToWX.postMessage("goods=" + JSON.stringify(winningInformation.value.sku) + "&record_id=" + winningInformation.value.record_id);
        //   } else {
        //     alert("请在APP内打开")
        //   }
        // }
      } catch (error) {
        Toast.fail("出现了一个交互错误");
      }
    };
    // 获取Token
    function getToken(params) {
      const token = getUrlParam("token");
      store.commit("SET_TOKEN", token);
    }
    // 获取活动ID
    function getLuckid(params) {
      const luck_id = getUrlParam("luck_id");
      store.commit("SET_Luckid", luck_id);
    }

    // 获取数据
    const getActivityData = async () => {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      await Promise.all([getRecord()]).then(() => {
        Toast.clear();
      });
    };
    // 跳转规则
    const jumpRule = () => {
      router.push({ name: "blindBoxRule" });
    };
    // 跳转中奖记录
    const jumpWinning = () => {
      router.push({ name: "blindBoxWinningList" });
    };
    // 跳转全部商品
    const allGoods = () => {
      router.push({ name: "allGoods" });
    }
    // 跳转抽奖记录
    const DrawRecord = () => {
      router.push({ name: "drawRecord" });
    }
    // 页面分享
    const share = () => {
      shareAddNum()

      // try {

      //   // if (getPhoneSystem() === "Android") {
      //   //   window.Android.shareToWX();
      //   //   shareAddNum(1);
      //   // }
      //   // if (getPhoneSystem() === "IOS") {
      //   //   window.webkit.messageHandlers.shareToWX.postMessage(null);
      //   //   shareAddNum(2);
      //   // } else {
      //   // }
      // } catch (error) {
      //   Toast.fail("出现了一个交互错误");
      // }
    };
    // 分享增加次数
    const shareAddNum = async (os_from) => {
      const res = await API.shareAddNum({
        token: store.state.Token,
        luck_id: store.state.Luckid
      });
      if (res) {

      }
    };
    // 页面返回
    const goBack = () => {
      try {
        if (getPhoneSystem() === "Android") {
          window.Android.goBack();
        }
        if (getPhoneSystem() === "IOS") {
          window.webkit.messageHandlers.goBack.postMessage(null);
        }
      } catch (error) {
        Toast.fail("出现了一个交互错误");
      }
    };
    onMounted(() => {
      getToken();
      getLuckid();
      getActivityData();
    });
    return {
      topBarShow,
      goBack,
      share,
      recordAnimationName,
      activityData,
      openBlindBox,
      gameNum,
      shareGetNum,
      changeBox,
      random,
      DrawRecord,
      jumpWinning,
      blindBoxs,
      isOpen,
      jumpRule,
      hiddenDialog,
      winningInformation,
      dialog,
      allGoods,
    };
  },
});
</script>
<style lang="scss">
.blindBox-container.active {
  margin-top: -80px;
}

.blindBox-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background: url("../../assets/blindBox-bg.png") no-repeat;
  background-size: 100% auto;

  .header {
    width: 100%;
    // background: url("../../assets/blindBox-bg.png") no-repeat;
    background-size: 100% auto;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 50px;
    z-index: 2;

    .van-nav-bar {
      background: transparent;

      .van-nav-bar__content {
        .van-nav-bar__left {
          i {
            color: #fff;
          }
        }

        .van-nav-bar__title {
          color: #fff;
        }

        .van-nav-bar__right {
          .van-nav-bar__text {
            color: #fff;
          }
        }
      }
    }

    .share-icon {
      width: 36px;
    }
  }

  .section {
    padding-top: 218px;
    margin-bottom: 58px;
    margin-top: 70px;

    .section-title {
      width: 620px;
      margin: 0 auto 34px;
      display: block;
    }

    .subtitle {
      text-align: center;

      span {
        font-size: 33px;
        font-weight: bold;
        color: #ffffff;
        line-height: 50px;
        text-shadow: 0px 4px 25px #0ea3ee;
        position: relative;

        &::after,
        &::before {
          content: "";
          width: 69px;
          height: 22px;
          background: url("../../assets/star-title.png") no-repeat;
          background-size: 100% 100%;
          position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
        }

        &::before {
          content: "";
          left: -80px;
        }

        &::after {
          content: "";
          right: -76px;
          transform: rotate(180deg);
        }
      }
    }
  }

  .article {
    width: 100%;
    height: 1486px;
    background: url("../../assets/blind-box-machine.png") no-repeat;
    background-size: 100% auto;

    .article-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 9px 0;
      margin-bottom: 20px;

      .icon {
        width: 34px;
        margin-right: 18px;
      }

      .text {
        max-width: 280px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 29px;
        font-weight: 400;
        color: #5b1900;
      }
    }

    .label {
      width: 594px;
      height: 124px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-around;
      margin: 0 auto 115px;
      overflow: hidden;

      .label-item {
        .label-item-content {
          width: 157px;
          height: 38px;
          line-height: 38px;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          background: linear-gradient(-55deg, #ffe7ae, #f5eb86);
          border: 1px solid #ffffff;
          border-radius: 17px;
          font-size: 20px;
          font-weight: 400;
          color: #5b1900;
          margin-bottom: 20px;
          text-align: center;
        }
      }
    }

    .blind-box-list {
      width: 519px;
      height: 524px;
      margin: 0 auto 80px;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      justify-content: space-between;

      .blind-box {
        width: 167px;
        height: 168px;
        background: url("../../assets/blind-box-bg.png") no-repeat;
        background-size: 100% auto;
        margin-bottom: 10px;

        .box {
          width: 100%;

          .img {
            margin: 14px 0 12px;

            img {
              width: 132px;
              height: 100px;
              display: block;
              margin: 0 auto;
            }
          }

          .btn {
            width: 103px;
            height: 32px;
            border-radius: 16px;
            font-size: 22px;
            font-weight: 400;
            color: #ffffff;
            line-height: 32px;
            text-align: center;
            margin: 0 auto;
          }
        }

        .close-box {
          .btn {
            background: #f04935;
          }
        }

        .open-box {
          .btn {
            background: #c3c2c2;
          }
        }
      }
    }

    .operation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 40px;
      margin-bottom: 180px;

      &.no-click {
        pointer-events: none;
      }

      .change {
        width: 293px;
        height: 109px;
        position: relative;

        .btn-text {
          width: 273px;
          height: 89px;
          background: url("../../assets/change-btn.png") no-repeat;
          background-size: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;

          .text {
            font-size: 34px;
            font-weight: bold;
            color: #e82509;
            line-height: 50px;
            text-shadow: 0px -1px 0px #ffffff;
            padding: 6px 0;
            text-align: center;
          }
        }

        .base {
          width: 293px;
          height: 78px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }

        &:active {
          .btn-text {
            top: 20px;
          }
        }
      }

      .random {
        width: 356px;
        height: 109px;
        position: relative;

        .btn {
          width: 100%;
          height: 100%;

          .btn-text {
            width: 336px;
            height: 89px;
            background: url("../../assets/random-btn.png") no-repeat;
            background-size: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            justify-content: center;

            .text {
              font-size: 34px;
              font-weight: bold;
              color: #e82509;
              line-height: 50px;
              text-shadow: 0px -1px 0px #ffffff;
              padding: 6px 0;
              text-align: center;
              margin-right: 10px;
            }

            .game-num {
              font-size: 25px;
              font-weight: bold;
              color: #ea2c2a;
              line-height: 70px;
              text-shadow: 0px -2px 0px #ffffff;
            }
          }

          .base {
            width: 356px;
            height: 99px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }

          &:active {
            .btn-text {
              top: 20px;
            }
          }
        }

        .tips {
          width: 284px;
          height: 61px;
          background: url("../../assets/share-tips-bg.png") no-repeat;
          background-size: 100%;
          position: absolute;
          top: -66px;
          right: 0;
          font-size: 24px;
          font-weight: bold;
          color: #ea2c2a;
          text-align: center;
          padding-top: 10px;
          animation: sharefloat 1s infinite;
          -webkit-animation: sharefloat 1s infinite;
          animation-direction: alternate;
          -webkit-animation-direction: alternate;

          @keyframes sharefloat {
            from {
              top: -66px;
            }

            to {
              top: -74px;
            }
          }
        }
      }
    }

    .recordList {
      width: 574px;
      height: 230px;
      margin: 0 auto;
      overflow: hidden;
      box-sizing: border-box;

      .record-box {
        overflow: hidden;
        transition: all 2s;

        .record-item {
          line-height: 60px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 24px;
          font-weight: 400;
          color: #fefeeb;

          span {
            width: calc(100% / 2);

            &:first-child {
              text-align: left;
            }

            &:last-child {
              text-align: right;
            }
          }
        }
      }

      .recordRotation {
        animation: roll var(--time) linear infinite;
      }

      @keyframes roll {
        from {
          transform: translateY(240px);
        }

        to {
          transform: translateY(-100%);
        }
      }
    }
  }

  .rule {
    width: 113px;
    height: 44px;
    background: linear-gradient(90deg, #9fdcf9, #42a7fa);
    border: 1px solid #cfeefd;
    border-width: 1px 0 1px 1px;
    box-shadow: 0px 9px 9px 0px #0ea3ee;
    border-radius: 22px 0px 0px 22px;
    font-size: 22px;
    font-weight: 400;
    color: #ffffff;
    line-height: 44px;
    position: fixed;
    right: 0;
    top: 300px;
    text-align: center;
  }

  .winning-record {
    width: 113px;
    height: 44px;
    background: linear-gradient(90deg, #9fdcf9, #42a7fa);
    border: 1px solid #cfeefd;
    border-width: 1px 0 1px 1px;
    box-shadow: 0px 9px 9px 0px #0ea3ee;
    border-radius: 22px 0px 0px 22px;
    font-size: 22px;
    font-weight: 400;
    color: #ffffff;
    line-height: 44px;
    position: fixed;
    right: 0;
    top: 370px;
    text-align: center;
  }
}

.dialog-box {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;

  .dialog {
    width: 634px;
    height: 793px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: url("../../assets/win-the-prize-goods.png") no-repeat;
    background-size: cover;
    padding-top: 230px;
    box-sizing: border-box;
    text-align: center;

    .dialog-content {
      width: 400px;
      font-size: 36px;
      font-weight: 400;
      color: #eb4a2d;
      margin: 0 auto 20px auto;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .goods {
      width: 260px;
      height: 260px;
      margin: 0 auto 44px;

      img {
        max-width: 100%;
        max-height: 100%;
        display: block;
        margin: 0 auto;
      }
    }

    .button {
      width: 400px;
      height: 90px;
      margin: auto;
      position: absolute;
      bottom: 82px;
      left: 0;
      right: 0;
    }
  }
}

.choujiang {
  width: 100%;
  text-align: center;
}
</style>